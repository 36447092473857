import { useDrawingArea } from '@mui/x-charts'
import { useSvgRef } from '@mui/x-charts/hooks'
import { useTranslation } from 'react-i18next'
import { fontFamily } from '../../../../shared-components/typography'
import { useTheme } from '@mui/material'

const InsightsMaxCapacityLine = ({}) => {
  const { t } = useTranslation('common')
  const drawingArea = useDrawingArea()
  const svgRef = useSvgRef()
  const themeHook = useTheme()

  if (!svgRef.current || !drawingArea) return null

  const textPosition = {
    // x: svgRef.current.getBoundingClientRect().left + drawingArea.left,
    // y: svgRef.current.getBoundingClientRect().top + drawingArea.top,
    x: drawingArea.left + drawingArea.width / 2,
    y: drawingArea.top + 3,
  }

  const linePosition = {
    // x: svgRef.current.getBoundingClientRect().left + drawingArea.left,
    // y: svgRef.current.getBoundingClientRect().top + drawingArea.top,
    x: drawingArea.left,
    y: drawingArea.top,
    x2: drawingArea.left + drawingArea.width,
    y2: drawingArea.top,
  }

  return (
    <>
      <line
        strokeWidth='1'
        strokeDasharray='7, 6'
        x1={linePosition.x}
        y1={linePosition.y}
        x2={linePosition.x2}
        y2={linePosition.y2}
        stroke={themeHook.palette.primary.main}
      ></line>
      <text
        style={{
          stroke: 'white',
          strokeWidth: '20px',
          transform: `translate(${textPosition.x}px, ${textPosition.y}px)`,
          textAnchor: 'middle',
          fontFamily: fontFamily,
          fontSize: '0.5rem',
          textAlign: 'center',
          fontWeight: 700,
          lineHeight: '100%',
          letterSpacing: '1.5px',
          textTransform: 'uppercase',
          transition: 'opacity 0.4s ease-in-out',
        }}
      >
        {t('insightsModal.maxCapacity')}
      </text>

      <text
        style={{
          transform: `translate(${textPosition.x}px, ${textPosition.y}px)`,
          textAnchor: 'middle',
          fontFamily: fontFamily,
          fontSize: '0.5rem',
          textAlign: 'center',
          fontWeight: 700,
          lineHeight: '100%',
          letterSpacing: '1.5px',
          textTransform: 'uppercase',
          transition: 'opacity 0.4s ease-in-out',
        }}
      >
        {t('insightsModal.maxCapacity')}
      </text>
    </>

    // <Popper
    //   sx={{
    //     pointerEvents: 'none',
    //     zIndex: 10000,
    //   }}
    //   open
    //   placement='top-end'
    //   anchorEl={generateVirtualElement(linePosition)}
    // >
    //   <Row
    //     sx={{
    //       width: drawingArea.width,
    //       alignItems: 'center',
    //       gap: 1,
    //     }}
    //   >
    //     <Box
    //       sx={(theme) => ({
    //         flexGrow: 1,
    //         borderBottom: `1px dashed ${theme.palette.primary.main}`,
    //         height: 1,
    //       })}
    //     />
    //     <Typography variant='captionSmall'>
    //       {t('insightsModal.maxCapacity')}
    //     </Typography>
    //     <Box
    //       sx={(theme) => ({
    //         flexGrow: 1,
    //         borderBottom: `1px dashed ${theme.palette.primary.main}`,
    //         height: 1,
    //       })}
    //     />
    //   </Row>
    // </Popper>
  )
}

export default InsightsMaxCapacityLine
