import * as React from 'react'
import { useContext } from 'react'
import NoSsr from '@mui/material/NoSsr'
import Popper from '@mui/material/Popper'
import { useAxisTooltip, useMouseTracker } from '@mui/x-charts/ChartsTooltip'
import { useSvgRef, useXAxis, useXScale, useYScale } from '@mui/x-charts/hooks'
import {
  generateVirtualElement,
  MousePosition,
} from '../generateVirtualElement'
import { Typography } from '@mui/material'
import Column from '../../../Column'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { getCurrentMaxCapacity } from '../../../../selectors/event'
import { useTranslation } from 'react-i18next'
import { insightsContext } from '../../../../types/insights'
import { InsightsLineData } from '../InsightsLineGraph'

interface Props {
  type: 'TICKETS' | 'CHECK_INS'
  data: InsightsLineData
}

const InsightsLineGraphTooltip = ({ type, data }: Props) => {
  const { t } = useTranslation('common')
  const { checkInsResult } = useContext(insightsContext)

  const maxCapacity = useSelector(getCurrentMaxCapacity)
  const tooltipData = useAxisTooltip()
  const mousePosition = useMouseTracker()
  const xAxis = useXAxis()
  const xScale = useXScale()
  const yScale = useYScale()
  const svgRef = useSvgRef()

  if (!tooltipData || !mousePosition || !xAxis.data) {
    // No data to display
    return null
  }

  if (
    svgRef.current === null ||
    tooltipData.seriesItems.length === 0 ||
    tooltipData.axisValue === null
  ) {
    return null
  }

  const getTotalAtPoint = () => {
    const pointIndex = data.xAxis.findIndex((i) =>
      dayjs(i).isSame(dayjs(tooltipData.axisValue)),
    )
    return data.yAxisCumulative[pointIndex]
  }

  getTotalAtPoint()
  // @ts-expect-error works
  const svgYPosition = yScale(tooltipData.seriesItems[0].value) ?? 0
  // @ts-expect-error works
  const svgXPosition = xScale(tooltipData.axisValue) ?? 0

  const tooltipPosition: MousePosition = {
    ...mousePosition,
    x: svgRef.current.getBoundingClientRect().left + svgXPosition,
    y: svgRef.current.getBoundingClientRect().top + svgYPosition,
  }

  return (
    <NoSsr>
      <Popper
        sx={{
          pointerEvents: 'none',
          zIndex: 10000,
        }}
        open
        placement={'top-end'}
        anchorEl={generateVirtualElement(tooltipPosition)}
      >
        <Column
          sx={{
            gap: '1px',
            alignItems: 'flex-end',
            p: 1,
          }}
        >
          <Typography variant='captionBold'>
            {tooltipData.seriesItems[0].formattedValue}
          </Typography>
          {type === 'TICKETS' && maxCapacity && (
            <Typography
              variant='captionSmall'
              sx={{
                color: 'text.secondary',
              }}
            >
              (
              {t('insightsModal.percentageOfCapacity', {
                percentage: (
                  (parseInt(tooltipData.seriesItems[0].formattedValue) /
                    maxCapacity) *
                  100
                ).toFixed(0),
              })}
              )
            </Typography>
          )}

          {type === 'CHECK_INS' && maxCapacity && (
            <Typography
              variant='captionSmall'
              sx={{
                color: 'text.secondary',
              }}
            >
              (
              {t('insightsModal.percentageCheckedIn', {
                percentage: ((getTotalAtPoint() / maxCapacity) * 100).toFixed(
                  0,
                ),
              })}
              )
            </Typography>
          )}

          <Typography
            variant='caption'
            sx={{
              color: 'primary.main',
            }}
          >
            {dayjs(tooltipData.axisValue).format('DD MMM')}
          </Typography>
        </Column>
      </Popper>
    </NoSsr>
  )
}

export default InsightsLineGraphTooltip
