import { Box, Fade, SxProps, Typography, useTheme } from '@mui/material'
import {
  areaElementClasses,
  AreaPlot,
  ChartsAxisHighlight,
  chartsAxisHighlightClasses,
  ChartsGrid,
  chartsGridClasses,
  ChartsXAxis,
  ChartsYAxis,
  lineElementClasses,
  lineHighlightElementClasses,
  LineHighlightPlot,
  LinePlot,
  markElementClasses,
  ResponsiveChartContainer,
} from '@mui/x-charts'
import dayjs from 'dayjs'
import InsightsLineGraphTooltip from './Tickets/InsightsLineGraphTooltip'
import { insightsBreakpoint } from '../../../types/insights'
import { fontFamily } from '../../../shared-components/typography'
import { useRef } from 'react'
import { Theme } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { getCurrentMaxCapacity } from '../../../selectors/event'
import InsightsMaxCapacityLine from './Tickets//InsightsMaxCapacityLine'
import { useTranslation } from 'react-i18next'

interface Props {
  data: InsightsLineData
  sx?: SxProps<Theme>
  type: 'TICKETS' | 'CHECK_INS'
}

export enum InsightsLineDataState {
  LOADING,
  LOADED,
  ERROR,
  NONE,
}

export interface InsightsLineData {
  xAxis: Date[]
  yAxisCumulative: number[]
  yAxisRaw: number[]
}

const InsightsLineGraph = ({ sx = [], data, type }: Props) => {
  const { t } = useTranslation('common')
  const themeHook = useTheme()
  const graphContainerRef = useRef(null)
  const maxCapacity = useSelector(getCurrentMaxCapacity)

  const dataToShow = data

  const isEmpty =
    dataToShow.yAxisCumulative[dataToShow.yAxisCumulative.length - 1] === 0
  // const isEmpty = true

  return (
    <Box
      ref={graphContainerRef}
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          overflow: 'hidden',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={{
          position: 'absolute',
          width: 1,
          height: 1,
          zIndex: 10,
          pointerEvents: 'none',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Fade in={isEmpty}>
          <Typography
            variant='captionSmall'
            sx={{
              maxWidth: 220,
              textAlign: 'center',
              color: 'text.secondary',
              bgcolor: 'background.paper',
              p: 2,
            }}
          >
            {t(
              type === 'TICKETS'
                ? 'insightsModal.registrationsEmpty'
                : 'insightsModal.checkinsEmpty',
            )}
          </Typography>
        </Fade>
        {/*  <Fade in={isEmpty}>*/}
        {/*    <Box*/}
        {/*      sx={{*/}
        {/*        width: 1,*/}
        {/*        height: 1,*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <InsightsGraphEmptyState page={InsightsPage.TICKETS} />*/}
        {/*    </Box>*/}
        {/*  </Fade>*/}
      </Box>
      {graphContainerRef.current && (
        <ResponsiveChartContainer
          resolveSizeBeforeRender
          sx={(theme) => ({
            [`& .${markElementClasses.root}`]: {
              display: 'none',
            },
            [`& .${lineHighlightElementClasses.root}`]: {
              fill: theme.palette.primary.main,
              color: theme.palette.primary.main,
              background: theme.palette.primary.main,
            },
            [`& .${lineElementClasses.root}`]: {
              stroke: theme.palette.primary.main,
            },
            [`& .${areaElementClasses.root}`]: {
              fill: 'url(#bashAreaGradient)',
            },
            [`& .${chartsGridClasses.line}`]: {
              strokeDasharray: 4,
            },
            [`& .${chartsAxisHighlightClasses.root}`]: {
              stroke: theme.palette.primary.main,
            },
            // opacity: isEmpty ? 0 : 1,
            transition: 'opacity 0.2s ease-in-out',
            [theme.breakpoints.down(insightsBreakpoint)]: {
              bgcolor: 'background.paper',
              borderRadius: 1,
            },
          })}
          xAxis={[
            {
              id: 'dataAxis',
              scaleType: 'utc',
              valueFormatter: (v) =>
                dayjs(v).format('ddd\nMMM DD').toUpperCase(),
              data: dataToShow.xAxis,
            },
          ]}
          yAxis={[
            {
              scaleType: 'linear',
              min: 0,
              max: type === 'TICKETS' ? (maxCapacity ?? undefined) : undefined,
            },
          ]}
          series={
            isEmpty
              ? []
              : [
                  {
                    xAxisId: 'dataAxis',
                    id: 'dataSeries',
                    type: 'line',
                    curve: 'linear',
                    data: dataToShow.yAxisCumulative,
                    area: true,
                    label: 'Data',
                    connectNulls: true,
                  },
                ]
          }
        >
          <defs>
            <linearGradient
              x1='50%'
              y1='0'
              x2='50%'
              y2='80%'
              gradientTransform='rotate(0)'
              id='bashAreaGradient'
              gradientUnits='userSpaceOnUse'
            >
              <stop offset='0' stopColor={themeHook.palette.primary.main} />
              <stop offset='1' stopColor='white' stopOpacity='0' />
            </linearGradient>
          </defs>

          <ChartsGrid horizontal vertical />
          <ChartsXAxis
            axisId={'dataAxis'}
            disableTicks
            tickLabelInterval={(_, index) => index % 2 === 0}
            sx={(theme) => ({
              '& line': {
                // @ts-expect-error theme does have palette
                stroke: theme.palette.grey[600] + ' !important',
              },
              '& text': {
                fontFamily: fontFamily + ' !important',
                fontWeight: 600 + ' !important',
                fontSize: '0.5rem' + ' !important',
                lineHeight: '0.6rem' + ' !important',
                letterSpacing: '0.39px' + ' !important',
              },
            })}
          />
          <ChartsYAxis
            disableTicks
            tickLabelInterval={(_, index) => index % 2 === 0}
            sx={(theme) => ({
              '& line': {
                // @ts-expect-error theme does have palette
                stroke: theme.palette.grey[600] + ' !important',
              },
              '& text': {
                fontFamily: fontFamily + ' !important',
                fontWeight: 600 + ' !important',
                fontSize: '0.5rem' + ' !important',
                lineHeight: '0.6rem' + ' !important',
                letterSpacing: '0.39px' + ' !important',
              },
            })}
          />
          {!isEmpty && <ChartsAxisHighlight x={'line'} />}
          <AreaPlot />
          <LinePlot />
          {!isEmpty && <LineHighlightPlot />}
          {!isEmpty && <InsightsLineGraphTooltip type={type} data={data} />}
          {!isEmpty && type === 'TICKETS' && <InsightsMaxCapacityLine />}
        </ResponsiveChartContainer>
      )}
    </Box>
  )
}

export default InsightsLineGraph
