import {
  InsightsBarDataItem,
  InsightsReachType,
} from '../../../../types/insights'
import {
  BarElementPath,
  BarPlot,
  ChartsLegend,
  ChartsXAxis,
  ChartsYAxis,
  LegendRendererProps,
  ResponsiveChartContainer,
} from '@mui/x-charts'
import { useTheme } from '@mui/material'
import { formatShortNumber } from '../../../../utils/eventFunctions'
import InsightsBarLabel from './InsightsBarLabel'
import InsightsBarLegend from './Legend/InsightsBarLegend'
import InsightsBarTooltip from './InsightsBarTooltip'

interface Props {
  data: InsightsBarDataItem[]
  type: InsightsReachType
}

const InsightsBarGraph = ({ data, type }: Props) => {
  const themeHook = useTheme()

  const ThemedLegend = (props: LegendRendererProps) => (
    <InsightsBarLegend
      {...props}
      position={{
        horizontal: 'right',
        vertical: 'top',
      }}
    />
  )

  const ThemedBar = (props) => (
    <BarElementPath
      {...props}
      sx={(theme) => ({
        '&:hover': {
          stroke: theme.palette.text.primary,
        },
      })}
    />
  )

  const highestValue = Math.max(...data.map((d) => d.count))

  return (
    <ResponsiveChartContainer
      resolveSizeBeforeRender={true}
      margin={{
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
      series={data.map((d) => ({
        id: d.type,
        type: 'bar',
        data: [d.count],
        label: d.type,
      }))}
      colors={[
        themeHook.palette.grey[800],
        themeHook.palette.grey[600],
        themeHook.palette.grey[400],
        themeHook.palette.grey[200],
      ]}
      yAxis={[
        {
          scaleType: 'linear',
          data: data.map((d) => d.count),
          max: highestValue * 1.25, // Allow space for legend
        },
      ]}
      xAxis={[
        {
          scaleType: 'band',
          data: ['xAxisData'],
        },
      ]}
    >
      <BarPlot
        barLabel={(item, context) => formatShortNumber(item.value)}
        slots={{
          // @ts-expect-error works
          barLabel: InsightsBarLabel,
          bar: ThemedBar,
        }}
      />

      <ChartsXAxis
      // disableTicks
      // tickLabelInterval={(value) => false}
      />
      <ChartsYAxis
      // disableTicks
      // tickLabelInterval={(v) => v === 0}
      />
      <ChartsLegend
        direction='column'
        slots={{
          legend: ThemedLegend,
        }}
      />
      <InsightsBarTooltip type={type} />
    </ResponsiveChartContainer>
  )
}

export default InsightsBarGraph
