import Column from '../../Column'
import {
  Box,
  CircularProgress,
  Collapse,
  Fade,
  Typography,
} from '@mui/material'
import Row from '../../Row'
import { ChevronRight } from '@mui/icons-material'
import AnimatedContent from '../../AnimatedContent'

interface Props {
  name: string
  stat: string | null
  extraStat: string | null
  description: string
  open: boolean
  onClick: () => void
}

const InsightsStatItem = ({
  open,
  onClick,
  name,
  stat,
  description,
  extraStat,
}: Props) => {
  return (
    <Column
      sx={(theme) => ({
        cursor: open ? undefined : 'pointer',
        gap: 0,
        alignItems: 'flex-start',
        justifyContent: 'center',
        bgcolor: 'background.paper',
        py: 1.5,
        px: 2,
        pr: 1.25,
        borderRadius: open ? 2 : 1.5,
        transition: 'all 0.2s ease-in-out',
        border: open
          ? `1.5px solid ${theme.palette.primary.main}`
          : '1.5px solid transparent',
        boxSizing: 'border-box',
        flexShrink: 1,
        flexGrow: open ? 1 : 0,
        '&:hover': {
          bgcolor: open ? undefined : 'grey.100',
        },
      })}
      onClick={onClick}
    >
      <Row
        sx={{
          gap: 0,
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: open ? '1rem' : '0.875rem',
            transition: 'all 0.2s ease-in-out',
          }}
          variant='subtitle1'
        >
          {name}
        </Typography>

        <Fade in={!open && stat != null}>
          <Row
            sx={{
              gap: 0.25,
              alignItems: 'center',
            }}
          >
            <ChevronRight
              sx={{
                width: 14,
                height: 14,
              }}
            />
          </Row>
        </Fade>
      </Row>
      <Collapse in={open}>
        <Column
          sx={{
            gap: 1.5,
            alignItems: 'flex-start',
            pt: 0.5,
          }}
        >
          <Typography
            sx={{
              color: 'text.secondary',
            }}
            variant='captionSmall'
          >
            {description}
          </Typography>

          <AnimatedContent
            firstContent={
              <CircularProgress
                sx={{
                  color: 'primary.800',
                }}
                size={14}
              />
            }
            secondContent={
              <Typography variant='h7'>
                {stat}
                {extraStat != null && (
                  <Box
                    component='span'
                    sx={{
                      color: 'text.secondary',
                    }}
                  >
                    /{extraStat}
                  </Box>
                )}
              </Typography>
            }
            showSecondContent={stat != null}
          />
        </Column>
      </Collapse>
    </Column>
  )
}

export default InsightsStatItem
