import { ChartsText } from '@mui/x-charts'
import clsx from 'clsx'
import { ChartsLegendItemProps } from '@mui/x-charts/ChartsLegend/ChartsLegendItem'

interface Props extends ChartsLegendItemProps {
  isHighlighted?: boolean
}

// Mostly copied from https://github.com/mui/mui-x/blob/v7.28.1/packages/x-charts/src/ChartsLegend/ChartsLegendItem.tsx
function InsightsLegendItem(props: Props) {
  const {
    id,
    positionY,
    label,
    positionX,
    color,
    gapX,
    gapY,
    itemMarkHeight,
    itemMarkWidth,
    markGap,
    labelStyle,
    classes,
    isHighlighted,
  } = props

  return (
    <g
      className={clsx(classes?.series, `${classes?.series}-${id}`)}
      transform={`translate(${gapX + positionX} ${gapY + positionY})`}
    >
      <circle
        className={classes?.mark}
        x={0}
        y={-itemMarkHeight / 2}
        r={3}
        width={itemMarkWidth}
        height={itemMarkHeight}
        fill={color}
        style={{ pointerEvents: 'none' }}
      />
      <ChartsText
        style={{
          pointerEvents: 'none',
          ...labelStyle,
          fontSize: isHighlighted ? '0.6rem' : labelStyle.fontSize,
          transition: 'font-size 0.2s ease-in-out',
          fontWeight: isHighlighted ? 500 : labelStyle.fontWeight,
        }}
        text={label}
        x={itemMarkWidth + markGap}
        y={0}
      />
    </g>
  )
}

export default InsightsLegendItem
