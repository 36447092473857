import FullscreenDialog from '../FullscreenDialog'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../../actions/modals'
import BashDialogTitle from '../BashDialogTitle'
import { useTranslation } from 'react-i18next'
import DialogContent from '@mui/material/DialogContent'
import Column from '../../Column'
import { useEffect, useState } from 'react'
import Row from '../../Row'
import InsightsTicketsGraph from './Tickets/InsightsTicketsGraph'
import {
  insightsBreakpoint,
  InsightsCheckInsResult,
  insightsContext,
  InsightsContextValue,
  InsightsGuestsResult,
  InsightsPage,
  InsightsReachResult,
  InsightsResult,
  InsightsTicketsResult,
} from '../../../types/insights'
import InsightsMenu from './InsightsMenu'
import apiClient from '../../../shared-components/utils/ApiClient'
import { useCurrentEvent } from '../../../utils/hooks'
import InsightsReach from './Reach/InsightsReach'
import InsightsHighlightsBar from './InsightsHighlightsBar'
import AnimatedStateContent from '../../AnimatedStateContent'
import { Box, CircularProgress } from '@mui/material'
import InsightsGuests from './Guests/InsightsGuests'
import InsightsCheckInsGraph from './CheckIns/InsightsCheckInsGraph'

const InsightsModal = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const open = useSelector((state: any) => state.modals.open.insights)
  const event = useCurrentEvent()
  const [page, setPage] = useState<InsightsPage>(InsightsPage.TICKETS)

  const [result, setResult] = useState<InsightsResult | null>(null)
  const [reachResult, setReachResult] = useState<InsightsReachResult | null>(
    null,
  )
  const [guestsResult, setGuestsResult] = useState<InsightsGuestsResult | null>(
    null,
  )
  const [ticketsResult, setTicketsResult] =
    useState<InsightsTicketsResult | null>(null)
  const [checkInsResult, setCheckInsResult] =
    useState<InsightsCheckInsResult | null>(null)
  const [loading, setLoading] = useState(false)
  const [loadingReach, setLoadingReach] = useState(false)
  const [loadingGuests, setLoadingGuests] = useState(false)
  const [loadingTickets, setLoadingTickets] = useState(false)
  const [loadingCheckIns, setLoadingCheckIns] = useState(false)

  const onClose = () => {
    dispatch(closeModal('insights'))
  }

  const loadInsights = async () => {
    setLoading(true)
    setResult(null)
    try {
      const result = await apiClient.insights.general(event.id, null) // TODO INSIGHTS: Organisation id
      if (result) {
        setResult(result)
      }
    } catch (e) {
      console.log('CATCH')
      console.error(e)
    }
    setLoading(false)
  }

  const loadReach = async () => {
    setLoadingReach(true)
    try {
      const result = await apiClient.insights.reach(event.id, null) // TODO INSIGHTS: Organisation id
      if (result) {
        setReachResult(result)
      }
    } catch (e) {
      console.log('CATCH')
      console.error(e)
    }
    setLoadingReach(false)
  }
  const loadGuests = async () => {
    setLoadingGuests(true)
    try {
      const result = await apiClient.insights.guests(event.id, null) // TODO INSIGHTS: Organisation id
      if (result) {
        setGuestsResult(result)
      }
    } catch (e) {
      console.log('CATCH')
      console.error(e)
    }
    setLoadingGuests(false)
  }
  const loadTickets = async () => {
    setLoadingTickets(true)
    try {
      const result = await apiClient.insights.tickets(event.id, null) // TODO INSIGHTS: Organisation id
      if (result) {
        setTicketsResult(result)
      }
    } catch (e) {
      console.log('CATCH')
      console.error(e)
    }
    setLoadingTickets(false)
  }
  const loadCheckIns = async () => {
    setLoadingCheckIns(true)
    try {
      const result = await apiClient.insights.checkIns(event.id, null) // TODO INSIGHTS: Organisation id
      if (result) {
        setCheckInsResult(result)
      }
    } catch (e) {
      console.log('CATCH')
      console.error(e)
    }
    setLoadingCheckIns(false)
  }

  const getLoadingCurrentPage = () => {
    if (page === InsightsPage.TICKETS) {
      return false
      // return loading
    } else if (page === InsightsPage.REACH) {
      return loadingReach
    } else if (page === InsightsPage.GUESTS) {
      return loadingGuests
    } else if (page === InsightsPage.CHECK_INS) {
      return loadingCheckIns
    } else if (page === InsightsPage.TICKETS) {
      return loadingTickets
    } else {
      return false
    }
  }

  useEffect(() => {
    if (open) {
      loadInsights()
      loadReach()
      loadGuests()
      loadTickets()
      loadCheckIns()
    } else {
      setResult(null)
      // setReachResult(null)
      // setGuestsResult(null)
    }
  }, [open])

  const contextValue: InsightsContextValue = {
    page,
    setPage,
    loading,
    loadingReach,
    loadingGuests,
    result,
    reachResult,
    guestsResult,
    ticketsResult,
    checkInsResult,
  }

  return (
    <insightsContext.Provider value={contextValue}>
      <FullscreenDialog
        slotProps={{
          paper: {
            sx: (theme) => ({
              maxWidth: 940,
              maxHeight: 538,
              height: 1,
              [theme.breakpoints.down(insightsBreakpoint)]: [
                {
                  maxHeight: '100%',
                  maxWidth: '100%',
                },
              ],
            }),
          },
        }}
        open={open}
        onClose={onClose}
      >
        <BashDialogTitle title={t('insightsModal.title')} onClose={onClose} />

        <DialogContent
          sx={{
            p: 3,
            bgcolor: 'grey.150',
            display: 'flex',
            gap: 3,
            border: 'none',
            flexGrow: 1,
            flexDirection: 'column',
          }}
          dividers
        >
          <Column
            sx={{
              width: 1,
              gap: 3,
              alignItems: 'flex-start',
              height: 'fit-content',
              flexGrow: 1,
            }}
          >
            <Row
              sx={(theme) => ({
                gap: 2,
                width: 1,
                display: 'grid',
                gridTemplateColumns: '180px 1fr',
                flexGrow: 1,
                [theme.breakpoints.down(insightsBreakpoint)]: {
                  gridTemplateColumns: '1fr',
                },
              })}
            >
              <InsightsMenu />

              <Box
                sx={(theme) => ({
                  position: 'relative',
                  alignSelf: 'stretch',
                  display: 'flex',
                  flexDirection: 'column',
                  bgcolor: 'background.paper',
                  borderRadius: 2,
                  height: 1,
                  [theme.breakpoints.down(insightsBreakpoint)]: {
                    aspectRatio: '1/1',
                    minWidth: 0,
                    width: 1,
                    bgcolor: 'transparent',
                  },
                })}
              >
                <AnimatedStateContent
                  getContent={({
                    page,
                    loading,
                  }: {
                    page: InsightsPage
                    loading: boolean
                  }) => (
                    <>
                      <Box
                        sx={{
                          width: 1,
                          height: 1,
                          maxHeight: '100%',
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end',
                        }}
                      >
                        {loading && (
                          <Box
                            sx={{
                              width: 1,
                              height: 1,
                              justifyContent: 'center',
                              alignItems: 'center',
                              display: 'flex',
                            }}
                          >
                            <CircularProgress
                              sx={{
                                height: 1,
                              }}
                            />
                          </Box>
                        )}
                        {!loading && page === InsightsPage.TICKETS && (
                          <InsightsTicketsGraph
                            sx={{
                              width: 1,
                              height: 1,
                            }}
                          />
                        )}

                        {!loading && page === InsightsPage.REACH && (
                          <InsightsReach
                            sx={(theme) => ({
                              p: 3,
                              height: 1,
                              width: 1,
                              [theme.breakpoints.down(insightsBreakpoint)]: {
                                p: 0,
                                pt: 2,
                                borderTop: `1px solid ${theme.palette.divider}`,
                              },
                            })}
                          />
                        )}
                        {!loading && page === InsightsPage.GUESTS && (
                          <InsightsGuests
                            sx={(theme) => ({
                              p: 3,
                              height: 1,
                              width: 1,
                              [theme.breakpoints.down(insightsBreakpoint)]: {
                                p: 0,
                                pt: 2,
                                borderTop: `1px solid ${theme.palette.divider}`,
                              },
                            })}
                          />
                        )}

                        {!loading && page === InsightsPage.CHECK_INS && (
                          <InsightsCheckInsGraph
                            sx={{
                              width: 1,
                              height: 1,
                            }}
                          />
                        )}
                      </Box>
                    </>
                  )}
                  state={{
                    page,
                    loading: getLoadingCurrentPage(),
                    // loading: true
                  }}
                  animateHeight={false}
                />
              </Box>
            </Row>

            <InsightsHighlightsBar />
          </Column>
        </DialogContent>
      </FullscreenDialog>
    </insightsContext.Provider>
  )
}

export default InsightsModal
