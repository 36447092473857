import { SxProps } from '@mui/material'
import { insightsContext } from '../../../../types/insights'
import { useContext, useMemo } from 'react'
import { Theme } from '@mui/material/styles'
import InsightsLineGraph from '../InsightsLineGraph'

interface Props {
  sx?: SxProps<Theme>
}

const InitialData = {
  xAxis: [],
  yAxisCumulative: [],
  yAxisRaw: [],
}

const InsightsCheckInsGraph = ({ sx = [] }: Props) => {
  const { checkInsResult } = useContext(insightsContext)

  const data = useMemo(() => {
    if (!checkInsResult) return InitialData
    const checkIns = checkInsResult.check_ins
    const xAxis = checkIns.map((r) => new Date(r.timestamp))
    const yAxisRaw = checkIns.map((r) => r.value)
    const yAxisCumulative = yAxisRaw.reduce(
      (accumulator, currentValue, index) => {
        if (index === 0) {
          accumulator.push(currentValue)
        } else {
          accumulator.push(accumulator[index - 1] + currentValue)
        }
        return accumulator
      },
      [] as number[],
    )

    return {
      xAxis,
      yAxisCumulative: yAxisCumulative,
      yAxisRaw: yAxisRaw,
    }
  }, [checkInsResult])

  return <InsightsLineGraph data={data} type={'CHECK_INS'} sx={sx} />
}

export default InsightsCheckInsGraph
