import {
  insightsBreakpoint,
  insightsContext,
  InsightsPage,
} from '../../../types/insights'
import InsightsStatItem from './InsightsStatItem'
import Row from '../../Row'
import { useTranslation } from 'react-i18next'
import Column from '../../Column'
import { Button, Menu, MenuItem, Typography } from '@mui/material'
import { ChevronRight } from '@mui/icons-material'
import { useContext, useRef, useState } from 'react'
import stats from '@pages/admin/stats'
import { formatShortNumber } from '../../../utils/eventFunctions'
import { useSelector } from 'react-redux'
import { getCurrentMaxCapacity } from '../../../selectors/event'

const InsightsMenu = () => {
  const { t } = useTranslation('common')
  const maxCapacity = useSelector(getCurrentMaxCapacity)

  const { page, setPage, result } = useContext(insightsContext)

  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null)
  const dropdownRef = useRef<HTMLButtonElement>(null)

  const titles = {
    [InsightsPage.TICKETS]: t('insightsModal.registrations'),
    [InsightsPage.REACH]: t('insightsModal.reach'),
    [InsightsPage.GUESTS]: t('insightsModal.guests'),
    [InsightsPage.CHECK_INS]: t('insightsModal.checkins'),
  }

  const descriptions = {
    [InsightsPage.TICKETS]: t('insightsModal.registrationsDescription'),
    [InsightsPage.REACH]: t('insightsModal.reachDescription'),
    [InsightsPage.GUESTS]: t('insightsModal.guestsDescription'),
    [InsightsPage.CHECK_INS]: t('insightsModal.checkinsDescription'),
  }

  const itemClicked = (page: InsightsPage) => {
    setPage(page)
    setAnchor(null)
  }

  return (
    <>
      <Column
        sx={(theme) => ({
          [theme.breakpoints.up(insightsBreakpoint)]: {
            display: 'none',
          },
          gap: 2,
        })}
      >
        <Button
          ref={dropdownRef}
          onClick={(e) => setAnchor(e.currentTarget)}
          sx={{
            alignItems: 'center',
            bgcolor: 'background.paper',
            borderRadius: 1.5,
            justifyContent: 'space-between',
            py: 1.5,
            px: 2,
          }}
        >
          <Row
            sx={{
              gap: 1,
              alignItems: 'center',
            }}
          >
            <Typography variant='body2Medium'>{titles[page]}</Typography>
            <Typography
              sx={{
                color: 'grey.800',
              }}
              variant='captionMedium'
            >
              {stats[page]}
            </Typography>
          </Row>

          <ChevronRight
            sx={{
              width: 16,
              height: 16,
              transform: 'rotate(90deg)',
              transition: 'transform 0.2s ease-in-out',
            }}
          />
        </Button>

        <InsightsStatItem
          name={titles[page]}
          stat={formatShortNumber(result?.[page]?.total ?? 0)}
          description={descriptions[page]}
          open={page === page}
          onClick={() => {}}
          extraStat={page === InsightsPage.CHECK_INS ? maxCapacity : null}
        />
      </Column>

      <Menu
        open={Boolean(anchor)}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchor(null)}
        disablePortal
        slotProps={{
          paper: {
            sx: {
              width: dropdownRef.current?.clientWidth,
            },
          },
        }}
      >
        {Object.keys(InsightsPage).map((key) => {
          const insightsPage = InsightsPage[key]
          const stat = result?.[insightsPage]?.total

          return (
            <MenuItem
              key={key}
              onClick={() => itemClicked(insightsPage)}
              sx={{
                alignItems: 'center',
                bgcolor: 'background.paper',
                justifyContent: 'space-between',
                py: 1.5,
                px: 2,
                width: 1,
              }}
            >
              <Row
                sx={{
                  gap: 1,
                  alignItems: 'center',
                }}
              >
                <Typography variant='body2Medium'>
                  {titles[insightsPage]}
                </Typography>
                <Typography
                  sx={{
                    color: 'grey.800',
                  }}
                  variant='captionMedium'
                >
                  {stat ? formatShortNumber(stat) : null}
                </Typography>
              </Row>
            </MenuItem>
          )
        })}
      </Menu>

      <Column
        sx={(theme) => ({
          gap: 1.5,
          alignItems: 'stretch',
          flexWrap: 'wrap',
          flexShrink: 1,
          flexBasis: 180,
          [theme.breakpoints.down(insightsBreakpoint)]: {
            display: 'none',
          },
        })}
      >
        {Object.keys(InsightsPage).map((key) => {
          const insightsPage = InsightsPage[key]
          const stat = result?.[insightsPage]?.total
          return (
            <InsightsStatItem
              key={key}
              name={titles[insightsPage]}
              stat={stat != null ? formatShortNumber(stat) : null}
              description={descriptions[insightsPage]}
              open={page === insightsPage}
              onClick={() => itemClicked(insightsPage)}
              extraStat={
                insightsPage === InsightsPage.CHECK_INS ? maxCapacity : null
              }
            />
          )
        })}
      </Column>
    </>
  )
}

export default InsightsMenu
